import React, { useState, useEffect } from 'react';
import './UploadPopup.css';

const UploadPopup = ({ isOpen, onClose, currentUser }) => {
  const resetState = () => {
    setUploadProgress(0);
    setIsLoading(false);
    setLoadingMessage('');
    setIsGoPro(false);
    setVehicle('BICYCLE');
    setVideoFile(null);
    setKmlFile(null);
    // setIsProcessing(false);
    // setProcessingSteps([]);
  }
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isGoPro, setIsGoPro] = useState(false);
  const [vehicle, setVehicle] = useState('BICYCLE');
  const [videoFile, setVideoFile] = useState(null);
  const [kmlFile, setKmlFile] = useState(null);
  // const [webSocket, setWebSocket] = useState(null);
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [processingSteps, setProcessingSteps] = useState([]);

  // useEffect(() => {
  //   const ws = new WebSocket('wss://140.203.17.132/ws');
  //   ws.onopen = () => console.log("WebSocket connection established");
  //   ws.onmessage = (event) => {
  //     if (event.data.includes("Processing complete")) {
  //       setTimeout(() => {
  //         resetState();
  //         setLoadingMessage("Processing complete! Select another video to upload.");
  //       }, 2000); // Reset 2s after completion
  //     }
  //     setLoadingMessage(event.data);
  //     setIsProcessing(true);
  //     setProcessingSteps(prevSteps => [...prevSteps, event.data]);
  //   };
  //   ws.onerror = (error) => console.log("WebSocket error:", error);
  //   ws.onclose = () => {
  //     console.log("WebSocket connection closed");
  //     setIsProcessing(false);
  //   };
  //   setWebSocket(ws);
  //   return () => { if (ws) ws.close(); };
  // }, []);

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (type === 'video') {
      setVideoFile(file);
    } else if (type === 'kml') {
      setKmlFile(file);
    }
  };

  const handleUpload = () => {
    console.log("Uploading video:", videoFile?.name);
    console.log("Uploading KML:", kmlFile?.name);
    if (!videoFile) {
      alert("Please select a video file before uploading.");
      return;
    }

    let kmlRenamed = null;
    if (kmlFile) {
      const videoName = videoFile.name.split('.').slice(0, -1).join('.'); // Remove extension
      kmlRenamed = new File([kmlFile], `${videoName}.kml`, { type: kmlFile.type });
    }



    // setProcessingSteps([]);
    const userUUID = currentUser ? currentUser.uid : "User not logged in";
    const formData = new FormData();
    formData.append('file', videoFile);
    if (kmlFile) formData.append('kmlFile', kmlRenamed);
    formData.append('userUUID', userUUID);
    formData.append('isGoPro', isGoPro ? 'true' : 'false');
    formData.append('vehicle', vehicle);

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentComplete);
        setLoadingMessage('Uploading...');
        console.log(`Upload progress: ${percentComplete}%`);
        if (percentComplete === 100) setLoadingMessage("Processing video, this might take a few minutes...");
      }
    };

    xhr.onloadstart = () => {
      setIsLoading(true);
      setUploadProgress(0);
      setLoadingMessage('Uploading...');
      console.log('Upload started');
    };

    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 202) {
        console.log('Upload complete');
        setLoadingMessage('Upload complete. You will see the route on the map in few minutes');
      } else if (xhr.status === 409) {
        alert('This video is already being processed or exists. Please rename and try again.');
        setIsLoading(false);
        setLoadingMessage('');
      } else {
        alert(`Failed to upload file: ${xhr.statusText}`);
        setIsLoading(false);
        setLoadingMessage('');
      }
    };
    

    xhr.onerror = () => {
      alert('Upload failed, please check your network connection.');
      setIsLoading(false);
      setLoadingMessage('');
    };

    const apiUrl = process.env.REACT_APP_API_URL;
    xhr.open('POST', `${apiUrl}/upload-file`, true);
    xhr.send(formData);
  };

  if (!isOpen) return null;
  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={() => { resetState(); onClose(); }}>X</button>
        <div className="upload-container">
          <p><strong>Note:</strong> If you upload a KML file, you must also select a video. KML is optional but cannot be uploaded alone.</p>

          <div className="file-upload-section">
            <label htmlFor="video-upload" className="custom-file-upload">Choose Video</label>
            {videoFile && <p>Selected Video: {videoFile.name}</p>}
            <input id="video-upload" type="file" accept="video/*, application/zip" onChange={(e) => handleFileChange(e, 'video')} style={{ display: 'none' }} />
          </div>

          <div className="file-upload-section">
            <label htmlFor="kml-upload" className="custom-file-upload">Choose KML File (Optional)</label>
            {kmlFile && <p>Selected KML: {kmlFile.name}</p>}
            <input id="kml-upload" type="file" accept=".kml" onChange={(e) => handleFileChange(e, 'kml')} style={{ display: 'none' }} />
          </div>

          <div className="gopro-checkbox">
            <label>
              <input type="checkbox" checked={isGoPro} onChange={(e) => setIsGoPro(e.target.checked)} /> Is this a GoPro video?
            </label>
          </div>

          <div className="vehicle-dropdown-label">
            <label htmlFor="vehicle-select">Select vehicle type:</label>
            <select id="vehicle-select" className="vehicle-dropdown" value={vehicle} onChange={(e) => setVehicle(e.target.value)} disabled={isLoading}>
              <option value="BICYCLE">Bicycle</option>
              <option value="E_BIKE">E-Bike</option>
              <option value="E_SCOOTER">E-Scooter</option>
              <option value="WHEELCHAIR">Wheelchair</option>
              <option value="STROLLER">Stroller</option>
              <option value="OTHER">Other</option>
            </select>
          </div>

          {isLoading && <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>{uploadProgress}%</div>}
          {/* {isProcessing && <div className="processing-steps">
            <p>Processing... Please wait.</p>
            <div className="spinner"></div>
            <ul>
              {processingSteps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ul>
          </div>} */}

          <button className={`upload-button ${!videoFile ? 'upload-button-disabled' : ''}`} onClick={handleUpload} disabled={isLoading || !videoFile}>Upload</button>
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;








































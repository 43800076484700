import React, { useState, useEffect } from 'react';
import logo from '../../uploads/logo.png';
import UploadPopup from '../Popups/UploadPopup';
import EditPopup from '../Popups/EditPopup';
import UserDropdown from '../Popups/UserLoginPopup';
import ManagePopup from '../Popups/ManagePopup';
import { flyToCoordinates } from '../utils/mapUtils'; // ✅ Import flyToCoordinates
import usePlotCoordinates from '../hooks/usePlotCoordinates'; // ✅ Import plotting function
import './MenuContainer.css';
import { use } from 'react';

const MenuContainer = ({
  mapRef,
  isAdmin,
  setHighlightedRoute,
  highlightedRoute,
  currentUser,
  groupedData,
  fetchData,
  setCurrentUser,
  setMapRefreshKey
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [isManagePopupOpen, setIsManagePopupOpen] = useState(false);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [focusedRouteId, setFocusedRouteId] = useState(null); // ✅ Store the focused route
  const [selectedRatings, setSelectedRatings] = useState([1, 2, 3, 4, 5]); // ✅ Store selected rating filters
  const [uploadStats, setUploadStats] = useState(null);


  const plotCoordinates = usePlotCoordinates(mapRef, selectedRatings, focusedRouteId);
  console.log("admin", isAdmin);
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("api", apiUrl)

  useEffect(() => {
    if (!isAdmin) return;

    const fetchStats = async () => {
      try {
        const response = await fetch(`${apiUrl}/upload-stats`);
        const data = await response.json();
        setUploadStats(data);
      } catch (error) {
        console.error("❌ Failed to fetch upload stats", error);
      }
    };

    fetchStats(); // Initial call

    const interval = setInterval(fetchStats, 5 * 60 * 1000); // every 5 mins

    return () => clearInterval(interval); // Clean up on unmount
  }, [isAdmin]);

  const deleteData = async (id) => {
    if (window.confirm(`Are you sure you want to delete video with ID ${id}?`)) {
      try {
        const response = await fetch(`${apiUrl}/data/${id}?userid=${currentUser?.uid}`, {
          method: 'DELETE'
        });

        if (!response.ok) {
          throw new Error('Failed to delete data');
        }

        // Clear highlighted route if it matches the deleted one
        if (highlightedRoute?.video === id) {
          setHighlightedRoute(null); // Unhighlight the route
        }

        // Refresh data to update the menu and map
        console.log("about to refresh data");
        fetchData(currentUser?.uid, true); // Force refresh data
        setMapRefreshKey(prevKey => prevKey + 1); // Trigger map refresh
      } catch (error) {
        console.error('Error deleting data:', error);
      }
    }
  };



  const handleFocusClick = (video) => {
    const coordinates = groupedData[video].items;
    if (mapRef.current) {
      mapRef.current.flyToCoordinates(coordinates);
    }
    setIsManagePopupOpen(false);
    console.log(video, coordinates, getAverageRating(coordinates));
    setHighlightedRoute({ video, coordinates, averageRating: getAverageRating(coordinates) });
  };
  const getNormalizedIriRating = (iri) => {
    if (iri <= 1.5) return 5;
    if (iri <= 2.5) return 4;
    if (iri <= 3.5) return 3;
    if (iri <= 4.5) return 2;
    return 1;
  };

  const getAverageRating = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return 0;
    const totalRating = coordinates.reduce((acc, coord) => acc + coord.cvatRating + getNormalizedIriRating(coord.iriRating), 0);
    return Math.round(totalRating / (2*coordinates.length));
  };

  return (
    <div className="menuContainer">
      <div className="header">
        <div className="right-section">
          <img src={logo} alt="Logo" className="header-logo" />
          <h1 className="pave-title">PavAnalytics</h1>
        </div>
        <div className="topbar-buttons">
          {currentUser && isAdmin && (
            <button className="uploadButton" onClick={() => setIsUploadPopupOpen(true)}>Upload</button>
          )}
          <button className="manageButton" onClick={() => setIsManagePopupOpen(true)}>Manage</button>
        </div>
        <UserDropdown
          mapRef={mapRef}
          fetchData={(userID) => fetchData(userID)}
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
        />
      </div>

      <ManagePopup
        groupedData={groupedData}
        isManagePopupOpen={isManagePopupOpen}
        setIsManagePopupOpen={setIsManagePopupOpen}
        handleFocusClick={handleFocusClick}
        highlightedRoute={highlightedRoute}
        deleteData={deleteData}
        currentUser={currentUser}
        isAdmin={isAdmin}
        setCurrentVideoId={setCurrentVideoId}
        setIsEditPopupOpen={setIsEditPopupOpen}
      />

      <UploadPopup
        isOpen={isUploadPopupOpen}
        onClose={() => {
          setIsUploadPopupOpen(false);
          fetchData(currentUser?.uid); // Refresh data after upload
        }}
        currentUser={currentUser}
      />

      <EditPopup
        isOpen={isEditPopupOpen}
        onClose={() => setIsEditPopupOpen(false)}
        currentVideoId={currentVideoId}
        groupedData={groupedData}
        fetchData={(userID) => fetchData(userID)}
        currentUser={currentUser}
      />
      {isAdmin && uploadStats && (
        <div className="upload-stats-box">
          <strong>📊 Upload Stats</strong><br />
          ⏳ In Progress: {uploadStats.startedlast5days}<br />
          ✅ Completed (Last 5 days): {uploadStats.completedlast5days}<br />
          ❌ Failed(Last 5 days): {uploadStats.failedlast5days}<br />
          📦 Total Completed Uploads: {uploadStats.totalCompleted}
        </div>
      )}

    </div>
  );
};

export default MenuContainer;

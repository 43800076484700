import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import './FilterContainer.css';

const FilterContainer = ({ onRatingChange, onDistressToggle }) => {
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [isDistressEnabled, setIsDistressEnabled] = useState(false);

  const handleToggle = (value) => {
    let updatedRatings;
    if (selectedRatings.includes(value)) {
      updatedRatings = selectedRatings.filter((item) => item !== value);
    } else {
      updatedRatings = [...selectedRatings, value];
    }
    setSelectedRatings(updatedRatings);
    onRatingChange(updatedRatings);
  };

  const handleAllToggle = () => {
    if (selectedRatings.length === 5) {
      setSelectedRatings([]);
      onRatingChange([]);
    } else {
      const allRatings = [1, 2, 3, 4, 5];
      setSelectedRatings(allRatings);
      onRatingChange(allRatings);
    }
  };

  const handleDistressToggle = () => {
    const newValue = !isDistressEnabled;
    setIsDistressEnabled(newValue);
    onDistressToggle(newValue);
  };

  return (
    <div className="filter-container">
      <div className="filter-section">
        <h3 className="ratingiri-text">Rating Filter</h3>
        <div className="button-group">
          {[1, 2, 3, 4, 5].map((value) => (
            <button
              key={value}
              className={`button rating-${value} ${selectedRatings.includes(value) ? 'active' : ''}`}
              onClick={() => handleToggle(value)}
            >
              {value}
            </button>
          ))}
          <button
            className={`button rating-all ${selectedRatings.length === 5 ? 'active' : ''}`}
            onClick={handleAllToggle}
          >
            All
          </button>
        </div>
      </div>
      
      <div className="filter-section">
        <h3>Distress Data</h3>
        <label className="distress-data-switch">
          <input type="checkbox" checked={isDistressEnabled} onChange={handleDistressToggle} />
          <span className="distress-data-slider round"></span>
        </label>
        <span className="distress-data-slider-label">Show Distress Data</span>
      </div>
    </div>
  );
};

export default FilterContainer;

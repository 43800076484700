import React, { useState, useEffect } from 'react';
import './EditPopup.css';

const EditPopup = ({ isOpen, onClose, currentVideoId, fetchData, groupedData }) => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [rating, setRating] = useState('');

  useEffect(() => {
    if (currentVideoId && groupedData[currentVideoId]) {
      const videoData = groupedData[currentVideoId];
      setRating(videoData.items[0].cvatRating || ''); // Use CVAT rating as Rating for simplicity
    }
  }, [currentVideoId, groupedData]);

  const updateRating = async (videoId, startTime, endTime, rating) => {
    const data = {
      video_id: videoId,
      cvat_rating: parseInt(rating),
      start_time: parseFloat(startTime),
      end_time: parseFloat(endTime),
    };

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/update-rating`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Failed to update ratings');
      }
      // Fetch the updated data after successfully updating the rating
      await fetchData();
    } catch (error) {
      console.error('Error updating ratings:', error);
    }
  };

  const handleUpdateRating = async () => {
    if (startTime && endTime) {
      await updateRating(currentVideoId, startTime, endTime, rating);
      await fetchData();
      onClose(); // Close the popup after fetching new data
    } else {
      console.error('Please provide both start time and end time.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h3>Edit Ratings</h3>
        <div className="input-group">
          <label htmlFor="start-time">Start Time</label>
          <input
            id="start-time"
            type="text"
            placeholder="Start Time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="end-time">End Time</label>
          <input
            id="end-time"
            type="text"
            placeholder="End Time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="rating">Rating</label>
          <input
            id="rating"
            type="text"
            placeholder="Rating"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          />
        </div>
        <button className="update-rating-button" onClick={handleUpdateRating}>Update Rating</button>
      </div>
    </div>
  );
};

export default EditPopup;

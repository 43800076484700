import mapboxgl from 'mapbox-gl';  // Import mapboxgl

// Clears the route for a specific video
export const clearRoute = (video, mapRef) => {
  if (!mapRef.current || !mapRef.current.isStyleLoaded()) {
    console.warn("Map style not fully loaded yet, skipping clearRoute.");
    return;
  }

  const sources = video 
    ? [video] 
    : Object.keys(mapRef.current.style.sourceCaches);

  sources.forEach((sourceId) => {
    const layerIdPoints = `${sourceId}-points`;
    const layerIdLine = `${sourceId}-line`;

    if (mapRef.current.getLayer(layerIdPoints)) {
      mapRef.current.removeLayer(layerIdPoints);
    }
    if (mapRef.current.getLayer(layerIdLine)) {
      mapRef.current.removeLayer(layerIdLine);
    }
    if (mapRef.current.getSource(sourceId)) {
      mapRef.current.removeSource(sourceId);
    }
  });
};

  
  // Flys to the specified coordinates on the map
  export const flyToCoordinates = (coordinates, mapRef) => {
    if (!mapRef.current) return;
  
    const bounds = coordinates.reduce(
      (bounds, coord) => bounds.extend([coord.lng, coord.lat]),
      new mapboxgl.LngLatBounds([coordinates[0].lng, coordinates[0].lat], [coordinates[0].lng, coordinates[0].lat])
    );
  
    mapRef.current.fitBounds(bounds, { padding: 50, animate: true });
  };
  
  // Gets the color based on cvatRating
  export const getColorByRating = (cvatRating) => {
    switch (cvatRating) {
      case 5:
        return '#00FF00';
      case 4:
        return '#0000FF';
      case 3:
        return '#FFFF00';
      case 2:
        return '#FFBF00';
      case 1:
        return '#FF0000';
      default:
        return '#FFFFFF';
    }
  };
  
// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

// Replace these with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_Wyedqzxsa7DcGKmp5iWnJEQ0Nxeo_WY",
  authDomain: "paveanalytics-95c79.firebaseapp.com",
  databaseURL: "https://paveanalytics-95c79-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "paveanalytics-95c79",
  storageBucket: "paveanalytics-95c79.appspot.com",
  messagingSenderId: "774061674593",
  appId: "1:774061674593:web:d0c08fd2f14b81d90d9a20",
  measurementId: "G-CKFRHG5VH9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, signInWithEmailAndPassword, signOut };

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAbzJJYrhUVOIYq4oK4b94uq79BGtgcmKs",
//   authDomain: "pav-analytics-2.firebaseapp.com",
//   databaseURL: "https://pav-analytics-2-default-rtdb.firebaseio.com",
//   projectId: "pav-analytics-2",
//   storageBucket: "pav-analytics-2.firebasestorage.app",
//   messagingSenderId: "466639363091",
//   appId: "1:466639363091:web:435fc50e6731c9635d33e6",
//   measurementId: "G-YWH4NETHYF"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const auth = getAuth(app);

// export { auth, signInWithEmailAndPassword, signOut };
import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Chart } from "chart.js/auto";
import usePlotCoordinates from './hooks/usePlotCoordinates';
import useDistressMarkers from './hooks/useDistressMarkers';
import 'mapbox-gl/dist/mapbox-gl.css';
import { flyToCoordinates } from './utils/mapUtils';
import * as turf from '@turf/turf';

mapboxgl.accessToken = 'pk.eyJ1Ijoic2FhaGlsa2hhbm5hIiwiYSI6ImNtNXF3aThpazA0N2Eya3M1enE2bnFzaHgifQ.cTx6W2-DsmY8BFHeNRwUyg'; // Replace with your Mapbox access token

const MapComponent = forwardRef(({ selectedRatings, distressEnabled, isAdmin, currentUser, groupedData, fetchData, setMapRefreshKey, setHighlightedRoute, highlightedRoute, setPointA, setPointB, isSelectingPoints, pointA, pointB, setIsSelectingPoints }, ref) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [distressMarkers, setDistressMarkers] = useState([]);
  const [routeIds, setRouteIds] = useState([]); // Track added routes for easy removal
  const [mapStyle, setMapStyle] = useState('mapbox://styles/saahilkhanna/cm5qwb8tj002m01sa6my9go6s');
  const [isDarkMode, setIsDarkMode] = useState(false);

  const plotCoordinates = usePlotCoordinates(mapRef, selectedRatings, setHighlightedRoute);  // Pass setHighlightedRoute to usePlotCoordinates
  const { plotDistressMarkers, clearDistressMarkers } = useDistressMarkers(mapRef, isAdmin, setMapRefreshKey, currentUser);
  const [selectedMarkers, setSelectedMarkers] = useState([]); // Store marker objects

  const resetMapStyle = () => {
    if (mapRef.current) {
      mapRef.current.setStyle(mapStyle); // Reset the map style to clear all layers and sources
      mapRef.current.once('styledata', () => {
        setMapIsReady(true); // Ensure map is ready for further actions
      });
    }
  };

  useImperativeHandle(ref, () => ({
    plotCoordinates,
    plotPointsAndRoute,
    flyToCoordinates: (coordinates) => flyToCoordinates(coordinates, mapRef),
    refreshData: () => fetchData(currentUser?.uid),
  }));
  
  const getNormalizedIriRating = (iri) => {
    if (iri <= 1.5) return 5;
    if (iri <= 2.5) return 4;
    if (iri <= 3.5) return 3;
    if (iri <= 4.5) return 2;
    return 1;
  };

  const getAverageRating = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return 0;
    const totalRating = coordinates.reduce((acc, coord) => acc + coord.cvatRating + getNormalizedIriRating(coord.iriRating), 0);
    return Math.round(totalRating / (2*coordinates.length));
  };

  const addMarker = (lngLat, color) => {
    const map = mapRef.current;
    if (!map) return;

    const marker = new mapboxgl.Marker({ color }) // Use green for A, blue for B
      .setLngLat([lngLat.lng, lngLat.lat])
      .addTo(map);

    setSelectedMarkers(prevMarkers => [...prevMarkers, marker]); // Store the marker
  };

  const clearMarkers = () => {
    if (selectedMarkers.length > 0) {
      selectedMarkers.forEach(marker => marker.remove()); // Remove each marker
      setSelectedMarkers([]); // Reset state AFTER markers are removed
      console.log("🗑️ All markers cleared.");
    }
  };





  // Define handleClick and handleMapClick first
  // Define handleClick and handleMapClick first
  const handleMapClick = (e) => {
    console.log("📌 Map clicked!");

    if (isSelectingPoints) {
      const lngLat = e.lngLat;
      console.log("✅ Click Position:", lngLat);

      const features = mapRef.current.queryRenderedFeatures(e.point);
      if (features.length === 0) {
        console.warn("⚠️ No route feature found at click position.");
        return;
      }

      console.log("📦 Full clicked feature:", features[0]);

      // const clickedFeature = features[0].properties;
      // console.log("🧾 Clicked feature properties:", clickedFeature);
      const clickedFeature = features[0].properties;
      console.log("🧾 Clicked feature properties:", clickedFeature);

      const clickedVideoId = clickedFeature.videoId;

      // ✅ ADD THIS BLOCK RIGHT HERE
      if (
        pointA &&
        (pointA.video_id !== clickedVideoId ||
          pointA.description !==
          groupedData[clickedVideoId]?.items.find(f => f.frame === clickedFeature.frame)?.description)
      ) {
        alert("⚠️ You cannot select points from different routes. Please select a point from the same route.");
        console.warn("❌ Point A and Point B are from different routes. Resetting selection.");
        setPointA(null);
        setPointB(null);
        clearMarkers();
        return; // 🛑 Prevent setting new Point A from this mismatched click
      }

      if (!highlightedRoute || highlightedRoute.video !== clickedVideoId) {
        const newRouteData = groupedData[clickedVideoId];
        if (newRouteData) {
          console.log("🔄 Route mismatch — updating highlightedRoute");
          setHighlightedRoute({
            video: clickedVideoId,
            coordinates: newRouteData.items,
            averageRating: getAverageRating(newRouteData.items)
          });

          setPointA({
            lng: lngLat.lng,
            lat: lngLat.lat,
            frame: clickedFeature.frame,
            time: clickedFeature.sampleTime,
            rating: clickedFeature.cvatRating,
            video_id: clickedFeature.videoId,
            description: newRouteData.items.find(f => f.frame === clickedFeature.frame)?.description || null
          });

          setPointB(null);
          clearMarkers();
          addMarker(lngLat, "purple");

          setIsSelectingPoints(true);
          return; // ✅ Only return here after setting the first point
        } else {
          console.warn("❌ No grouped data found for clicked route:", clickedVideoId);
          return;
        }
      }


      const frameData = {
        lng: lngLat.lng,
        lat: lngLat.lat,
        frame: clickedFeature.frame,
        time: clickedFeature.sampleTime,
        rating: clickedFeature.cvatRating,
        video_id: clickedFeature.videoId, // ✅ Use video from clickedFeature if available
        description: highlightedRoute.coordinates.find(f => f.frame === clickedFeature.frame)?.description || null // ✅ Find correct description
      };

      console.log("🎥 Clicked Frame Video ID:", frameData.video_id);
      console.log("📜 Clicked Frame Route Description:", frameData.description);

      if (!pointA) {
        console.log("🟢 Setting Point A:", frameData);
        setPointA(frameData);
        addMarker(lngLat, "purple");
      } else {
        console.log("🎥 Point A Video ID:", pointA.video_id);
        console.log("📜 Point A Route Description:", pointA.description);
        console.log("🎥 Point B Video ID (Attempted):", frameData.video_id);
        console.log("📜 Point B Route Description (Attempted):", frameData.description);

        // ✅ Check if Point B is from the same video AND route description
        // if (pointA.video_id !== frameData.video_id || pointA.description !== frameData.description) {
        //   alert("⚠️ You cannot select points from different routes. Please select a point from the same route.");
        //   console.warn("❌ Point A and Point B are from different routes. Resetting selection.");
        //   setPointA(null);
        //   setPointB(null);
        //   clearMarkers();
        //   return;
        // }

        console.log("🔵 Setting Point B:", frameData);
        setPointB(frameData);
        addMarker(lngLat, "orange");

        // ✅ Call computeFrameDetails only if both points are from the same route
        console.log("🚀 Calling computeFrameDetails with latest values...");
        computeFrameDetails(pointA, frameData);

        setIsSelectingPoints(false);
      }
    }
  };

  const computeFrameDetails = (pointA, pointB) => {
    console.log("✅ computeFrameDetails is running!");
    console.log("Point A:", pointA);
    console.log("Point B:", pointB);
    console.log("Highlighted Route:", highlightedRoute);

    if (!pointA || !pointB || !highlightedRoute) {
      console.warn("⚠️ Point A or B is missing.");
      return;
    }

    const routeFrames = highlightedRoute.coordinates;
    console.log("📸 All Frames in Route:", highlightedRoute.coordinates.map(f => f.frame));

    const extractFrameNumber = (frameString) => {
      const match = frameString.match(/frame_(\d+)\.jpeg/);
      return match ? parseInt(match[1], 10) : null;
    };

    const startFrame = extractFrameNumber(pointA.frame);
    const endFrame = extractFrameNumber(pointB.frame);

    const minFrame = Math.min(startFrame, endFrame);
    const maxFrame = Math.max(startFrame, endFrame);

    console.log(`🔍 Finding frames between ${pointA.frame} and ${pointB.frame} (${minFrame} to ${maxFrame})`);

    const framesBetween = routeFrames.filter((frame) => {
      const frameNum = extractFrameNumber(frame.frame);
      return frameNum !== null && frameNum >= minFrame && frameNum <= maxFrame;
    });

    console.log("🎯 Frames Found:", framesBetween.map(f => f.frame));

    if (framesBetween.length === 0) {
      console.warn(`⚠️ No frames found between ${pointA.frame} and ${pointB.frame}`);
      return;
    }

    let totalDistance = 0;
    for (let i = 1; i < framesBetween.length; i++) {
      const prev = framesBetween[i - 1];
      const current = framesBetween[i];

      totalDistance += turf.distance(
        [prev.gps_long_deg, prev.gps_lat_deg],
        [current.gps_long_deg, current.gps_lat_deg],
        { units: "kilometers" }
      );
    }

    const timeDifference = Math.abs(pointB.time - pointA.time) / 60;

    const totalRatings = framesBetween.reduce((sum, frame) => sum + frame.cvatRating, 0);
    const averageRating = totalRatings / framesBetween.length;

    const totalIriRatings = framesBetween.reduce((sum, frame) => sum + frame.iriRating, 0);
    const averageIriRating = totalIriRatings / framesBetween.length;

    const getNormalizedIriRating = (iri) => {
      if (iri <= 1.5) return 5;
      if (iri <= 2.5) return 4;
      if (iri <= 3.5) return 3;
      if (iri <= 4.5) return 2;
      return 1;
    };

    // ✅ Normalize each frame's IRI rating individually
    const normalizedIriRatings = framesBetween.map(frame => getNormalizedIriRating(frame.iriRating));

    let normalizedIriRating = getNormalizedIriRating(averageIriRating);
    let normalizedOverallRating = (averageRating + normalizedIriRating) / 2;
    let iriRatingDisplay = `${normalizedIriRating}`;

    // ✅ Count occurrences of ratings
    const countOccurrences = (data) => {
      return data.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});
    };

    const cvatCounts = countOccurrences(framesBetween.map(frame => frame.cvatRating));
    const iriCounts = countOccurrences(normalizedIriRatings);

    // ✅ Create bar charts inside the popup
    const generateChartHTML = (label, canvasId) => {
      return `
        <div style="width: 100%; max-width: 200px; height: 220px; margin: auto;">
          <h4 style="margin: 8px 0; font-size: 12px;">${label}</h4>
          <canvas id="${canvasId}" style="width: 100%; height: 180px;"></canvas>
        </div>
      `;
    };


    const popup = new mapboxgl.Popup({ closeOnClick: true })
      .setLngLat([pointB.lng + 0.001, pointB.lat])
      .setHTML(`
      <div style="font-family: Arial, sans-serif; padding: 10px; max-width: 220px; box-sizing: border-box;">
        <h3 style="margin: 0 0 10px 0; font-size: 14px; font-weight: bold; text-align: center;">Route Details</h3>
    
        <p style="margin: 4px 0; font-size: 12px;">🚲 Distance: <strong>${totalDistance.toFixed(2)} km</strong></p>
        <p style="margin: 4px 0; font-size: 12px;">⏳ Time: <strong>${timeDifference.toFixed(2)} min</strong></p>
        <p style="margin: 4px 0; font-size: 12px;">📸 Avg Visual Rating: <strong>${averageRating.toFixed(1)}</strong></p>
        <p style="margin: 4px 0; font-size: 12px;">📏 Avg IRI Rating: <strong>${iriRatingDisplay}</strong></p>
        <p style="margin: 4px 0; font-size: 12px;">⭐ Overall Rating: <strong>${normalizedOverallRating.toFixed(1)}</strong></p>
    
        <div style="width: 100%; max-width: 180px; height: 160px; margin-top: 10px;">
          <h4 style="margin: 4px 0; font-size: 12px; text-align: center;">CVAT Rating</h4>
          <canvas id="cvatChart" style="width: 100%; height: 120px; display: block;"></canvas>
        </div>
    
        <div style="width: 100%; max-width: 180px; height: 160px; margin-top: 16px;">
          <h4 style="margin: 4px 0; font-size: 12px; text-align: center;">IRI Rating</h4>
          <canvas id="iriChart" style="width: 100%; height: 120px; display: block;"></canvas>
        </div>
      </div>
    `)
      .addTo(mapRef.current);


    console.log("📌 Popup displayed with route details including normalized IRI rating and bar charts.");

    // ✅ Render the bar charts after the popup is added to the DOM
    setTimeout(() => {
      const createBarChart = (canvasId, data, label) => {
        const ctx = document.getElementById(canvasId).getContext("2d");
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: Object.keys(data),
            datasets: [{
              label: label,
              data: Object.values(data),
              backgroundColor: "rgba(75, 192, 192, 0.6)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1
            }]
          },
          options: {
            responsive: false,
            scales: {
              y: { beginAtZero: true }
            }
          }
        });
      };

      createBarChart("cvatChart", cvatCounts, "Distribution");
      createBarChart("iriChart", iriCounts, "Distribution");
    }, 500); // Ensures elements are present before chart initialization
  };


  // const computeFrameDetails = (pointA, pointB) => {
  //   console.log("✅ computeFrameDetails is running!");
  //   console.log("Point A:", pointA);
  //   console.log("Point B:", pointB);
  //   console.log("Highlighted Route:", highlightedRoute);

  //   if (!pointA || !pointB || !highlightedRoute) {
  //     console.warn("⚠️ Point A or B is missing.");
  //     return;
  //   }

  //   const routeFrames = highlightedRoute.coordinates;
  //   console.log("📸 All Frames in Route:", highlightedRoute.coordinates.map(f => f.frame));

  //   const extractFrameNumber = (frameString) => {
  //     const match = frameString.match(/frame_(\d+)\.jpeg/);
  //     return match ? parseInt(match[1], 10) : null;
  //   };

  //   const startFrame = extractFrameNumber(pointA.frame);
  //   const endFrame = extractFrameNumber(pointB.frame);

  //   const minFrame = Math.min(startFrame, endFrame);
  //   const maxFrame = Math.max(startFrame, endFrame);

  //   console.log(`🔍 Finding frames between ${pointA.frame} and ${pointB.frame} (${minFrame} to ${maxFrame})`);

  //   const framesBetween = routeFrames.filter((frame) => {
  //     const frameNum = extractFrameNumber(frame.frame);
  //     return frameNum !== null && frameNum >= minFrame && frameNum <= maxFrame;
  //   });

  //   console.log("🎯 Frames Found:", framesBetween.map(f => f.frame));

  //   if (framesBetween.length === 0) {
  //     console.warn(`⚠️ No frames found between ${pointA.frame} and ${pointB.frame}`);
  //     return;
  //   }

  //   let totalDistance = 0;
  //   for (let i = 1; i < framesBetween.length; i++) {
  //     const prev = framesBetween[i - 1];
  //     const current = framesBetween[i];

  //     totalDistance += turf.distance(
  //       [prev.gps_long_deg, prev.gps_lat_deg],
  //       [current.gps_long_deg, current.gps_lat_deg],
  //       { units: "kilometers" }
  //     );
  //   }

  //   const timeDifference = Math.abs(pointB.time - pointA.time);

  //   const totalRatings = framesBetween.reduce((sum, frame) => sum + frame.cvatRating, 0);
  //   const averageRating = totalRatings / framesBetween.length;

  //   const totalIriRatings = framesBetween.reduce((sum, frame) => sum + frame.iriRating, 0);
  //   const averageIriRating = totalIriRatings / framesBetween.length;

  //   const getNormalizedIriRating = (iri) => {
  //     if (iri <= 1.5) return 5;
  //     if (iri <= 2.5) return 4;
  //     if (iri <= 3.5) return 3;
  //     if (iri <= 4.5) return 2;
  //     return 1;
  //   };

  //   let normalizedIriRating = getNormalizedIriRating(averageIriRating);
  //   let normalizedOverallRating = (averageRating + normalizedIriRating) / 2;
  //   let iriRatingDisplay = `${normalizedIriRating}`;

  //   // Removed the "not available for mobile" check

  //   const popup = new mapboxgl.Popup({ closeOnClick: true })
  //     .setLngLat([pointB.lng + 0.0001, pointB.lat])
  //     .setHTML(`
  //     <div style="font-family: Arial, sans-serif; padding: 8px;">
  //       <h3 style="margin: 0; font-size: 14px; font-weight: bold;">Route Details</h3>
  //       <p style="margin: 4px 0; font-size: 12px;">🚲 Distance: <strong>${totalDistance.toFixed(2)} km</strong></p>
  //       <p style="margin: 4px 0; font-size: 12px;">⏳ Time: <strong>${timeDifference.toFixed(2)} sec</strong></p>
  //       <p style="margin: 4px 0; font-size: 12px;">📸 Average Visual Rating: <strong>${averageRating.toFixed(1)}</strong></p>
  //       <p style="margin: 4px 0; font-size: 12px;">📏 Average IRI Rating: <strong>${iriRatingDisplay}</strong></p>
  //       <p style="margin: 4px 0; font-size: 12px;">⭐ Overall Rating: <strong>${normalizedOverallRating.toFixed(1)}</strong></p>
  //     </div>
  //   `)
  //     .addTo(mapRef.current);

  //   console.log("📌 Popup displayed with route details including normalized IRI rating.");
  // };


  const handleClick = (e) => {
    console.log("Map clicked");
    clearMarkers();

    const features = mapRef.current.queryRenderedFeatures(e.point);
    const zoomLevel = mapRef.current.getZoom();

    if (features.length > 0) {
      const clickedFeature = features[0];
      console.log("Clicked feature:", clickedFeature);

      const clickedVideoId = clickedFeature.properties.videoId;
      if (groupedData[clickedVideoId]) {
        const routeData = groupedData[clickedVideoId];

        if (zoomLevel >= zoomThreshold) {
          // Popup logic
          const point = routeData.items.find(item => item.frame === clickedFeature.properties.frame);
          if (point) {
            const imageUrl = `${apiUrl}/static/${point.frame}`;


            const getNormalizedIriRating = (iri) => {
              if (iri <= 1.5) return 5;
              if (iri <= 2.5) return 4;
              if (iri <= 3.5) return 3;
              if (iri <= 4.5) return 2;
              return 1;
            };

            let normalizedIriRating = getNormalizedIriRating(point.iriRating);
            let iriRatingDisplay = `${normalizedIriRating}`;

            // Removed the "not available for mobile" check

            new mapboxgl.Popup()
              .setLngLat([point.gps_long_deg, point.gps_lat_deg])
              .setHTML(`
              <div>
                <strong>Vehicle:</strong> ${point.vehicle} <br/>
                <strong>Sample Time:</strong> ${point.sampleTime} <br/>
                <strong>Visual Rating:</strong> ${point.cvatRating} <br/>
                <strong>IRI Rating:</strong> ${iriRatingDisplay} <br/>
                <img src="${imageUrl}" alt="Frame Image" style="width: 200px; height: auto;" />
              </div>
            `)
              .addTo(mapRef.current);

            // NEW: Update the left panel with the same frame
            setHighlightedRoute({
              video: clickedVideoId,
              coordinates: routeData.items,
              averageRating: getAverageRating(routeData.items),
              selectedFrameIndex: routeData.items.findIndex(item => item.frame === point.frame) // Store selected frame index
            });
          }
        } else {
          // Normal left panel behavior when zoomed out
          setHighlightedRoute({
            video: clickedVideoId,
            coordinates: routeData.items,
            averageRating: getAverageRating(routeData.items)
          });
        }
      } else {
        console.warn("No route data found for the clicked feature's videoId.");
      }
    } else {
      console.warn("No features found at the clicked point.");
      clearMarkers();
    }
  };




  const fetchRoutedPath = async (pointA, pointB) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${pointA.lng},${pointA.lat};${pointB.lng},${pointB.lat}?geometries=geojson&access_token=${mapboxgl.accessToken}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.routes && data.routes.length > 0) {
        return data.routes[0].geometry;
      }
      return null;
    } catch (error) {
      console.error("Error fetching routed path:", error);
      return null;
    }
  };

  const sampleEveryFiveMeters = (coordinates) => {
    const sampledCoords = [coordinates[0]]; // Start with the first coordinate
    let accumulatedDistance = 0;

    for (let i = 1; i < coordinates.length; i++) {
      const [prevLng, prevLat] = sampledCoords[sampledCoords.length - 1];
      const [lng, lat] = coordinates[i];

      const distance = turf.distance([prevLng, prevLat], [lng, lat], { units: 'meters' });
      accumulatedDistance += distance;

      if (accumulatedDistance >= 5) {
        sampledCoords.push([lng, lat]);
        accumulatedDistance = 0;
      }
    }

    return sampledCoords;
  };



  const plotPointsAndRoute = async (pointA, pointB) => {
    if (mapRef.current) {
      // Plot markers for points A and B
      new mapboxgl.Marker({ color: 'red' }).setLngLat([pointA.lng, pointA.lat]).addTo(mapRef.current);
      new mapboxgl.Marker({ color: 'blue' }).setLngLat([pointB.lng, pointB.lat]).addTo(mapRef.current);

      // Fetch routed path from Mapbox
      const routeGeometry = await fetchRoutedPath(pointA, pointB);
      if (!routeGeometry) {
        console.error("No route found between the points.");
        return;
      }

      // Sample the route coordinates every 5 meters
      const sampledCoordinates = sampleEveryFiveMeters(routeGeometry.coordinates);

      // Plot the GeoJSON line
      const routeGeoJSON = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: sampledCoordinates,
        },
      };

      // Add the route as a line layer
      if (mapRef.current.getSource("route")) {
        mapRef.current.getSource("route").setData(routeGeoJSON);
      } else {
        mapRef.current.addSource("route", {
          type: "geojson",
          data: routeGeoJSON,
        });
        mapRef.current.addLayer({
          id: "route-line",
          type: "line",
          source: "route",
          paint: {
            "line-color": "#888",
            "line-width": 4,
          },
        });
      }
    }
  };


  useEffect(() => {
    if (!mapRef.current && mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapStyle,
        center: [-6.2603, 53.3498],
        zoom: 7,
      });

      mapRef.current.on('load', () => {
        setMapIsReady(true); // Set map as ready when initial load is complete
      });
    }
  }, []);

  useEffect(() => {
    if (mapIsReady) {
      if (isSelectingPoints) {
        mapRef.current.off('click', handleClick); // Temporarily remove route click listener
        mapRef.current.on('click', handleMapClick); // Enable map click for selecting points
      } else {
        mapRef.current.off('click', handleMapClick); // Remove point selection listener
        mapRef.current.on('click', handleClick); // Re-enable route click listener
      }
    }
    return () => {
      if (mapIsReady) {
        mapRef.current.off('click', handleMapClick);
        mapRef.current.off('click', handleClick);
      }
    };
  }, [mapIsReady, isSelectingPoints, handleClick, handleMapClick]);



  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(mapStyle);
      mapRef.current.once('style.load', () => {
        const interval = setInterval(() => {
          if (mapRef.current.isStyleLoaded()) {
            clearInterval(interval);
            setMapIsReady(true); // Confirm that the map style is fully loaded

            // Re-plot the routes after changing the map style
            if (groupedData && Object.keys(groupedData).length > 0) {
              Object.keys(groupedData).forEach((video) => {
                const coordinates = groupedData[video].items;
                if (coordinates && coordinates.length > 0) {
                  plotCoordinates(coordinates, video);
                }
              });
            }

            // Re-plot distress markers if enabled
            if (distressEnabled) {
              fetchDistressData();
            }
          }
        }, 100);
      });
    }
  }, [mapStyle]);

  useEffect(() => {
    if (mapIsReady) {
      if (!currentUser?.uid) {
        console.log("User logged out or no userID provided, resetting map style.");
        resetMapStyle(); // Reset the map style on logout to clear routes
        setDistressMarkers([]);
        return;
      }

      // Plot coordinates if there is groupedData
      if (groupedData && Object.keys(groupedData).length > 0) {
        const newRouteIds = Object.keys(groupedData).map((video) => {
          const coordinates = groupedData[video].items;
          if (coordinates && coordinates.length > 0) {
            plotCoordinates(coordinates, video);  // Call plotCoordinates to plot the route
          }
          return video;  // Return the actual video ID for route identification
        });
        setRouteIds(newRouteIds); // Store the new unique route IDs for later removal
      }
    }
  }, [mapIsReady, currentUser, groupedData, plotCoordinates]);

  // Zoom and Popup logic for routes
  const zoomThreshold = 15;


  // Attach the click event listener for the map
  useEffect(() => {
    if (mapIsReady) {
      mapRef.current.on('click', handleClick);
    }

    return () => {
      if (mapIsReady) {
        mapRef.current.off('click', handleClick);  // Remove event listener when component unmounts
      }
    };
  }, [mapIsReady, groupedData]);

  const apiUrl = process.env.REACT_APP_API_URL;
  // Fetch and render distress markers
  const fetchDistressData = async () => {
    try {
      const response = await fetch(`${apiUrl}/fetch-distress`);
      const data = await response.json();

      // Log raw data for comparison
      console.log("Raw distress data:", data);

      // Filter data to ensure valid latitude and longitude
      const validData = data.filter((item) => {
        const [lat, lng] = item.location.split(',').map(coord => parseFloat(coord.trim()));

        // Check if lat and lng are valid numbers
        const isValid = !isNaN(lat) && !isNaN(lng);
        if (!isValid) {
          console.warn(`Invalid coordinates skipped: ${item.location}`);
        }
        return isValid;
      });

      // Log valid data to confirm correct filtering
      console.log("Valid distress data after filtering:", validData);

      // Plot distress markers if valid data is found
      if (validData.length > 0) {
        plotDistressMarkers(validData, setDistressMarkers);
      }
    } catch (error) {
      console.error('Error fetching distress data:', error);
    }
  };

  useEffect(() => {
    if (mapRef.current && distressEnabled) {
      fetchDistressData();
    } else if (mapRef.current && !distressEnabled) {
      clearDistressMarkers(setDistressMarkers);
    }
  }, [distressEnabled, plotDistressMarkers, clearDistressMarkers]);

  const handleMapStyleChange = (e) => {
    const newStyle = e.target.checked ? 'mapbox://styles/saahilkhanna/cm6839xwf005y01s35aindffi' : 'mapbox://styles/saahilkhanna/cm5qwb8tj002m01sa6my9go6s';
    setMapStyle(newStyle);
    setIsDarkMode(e.target.checked);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <label style={{ position: 'absolute', top: 100, right: 10, zIndex: 2, backgroundColor: isDarkMode ? 'white' : 'black', color: isDarkMode ? 'black' : 'white', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
        <input type="checkbox" onChange={handleMapStyleChange} style={{ marginRight: '5px' }} checked={isDarkMode} /> Toggle Dark Mode
      </label>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
});

export default MapComponent;
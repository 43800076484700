import { useState, useEffect, useCallback } from 'react';

const useFetchData = () => {
  const [groupedData, setGroupedData] = useState([]);
  const [lastFetchedUserID, setLastFetchedUserID] = useState(null);

  const fetchData = useCallback(async (userID, forceRefresh = false) => {
    console.log("Inside fetchData - userID:", userID, "forceRefresh:", forceRefresh);
    if (!forceRefresh && userID === lastFetchedUserID) {
      // If the userID hasn't changed and force refresh is not requested, skip fetching
      return;
    }

    console.log("fetchData called with userID:", userID);

    if (!userID) {
      console.log("No user ID provided, returning empty array.");
      setGroupedData([]); 
      setLastFetchedUserID(null); 
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const url = `${apiUrl}/data?userid=${userID}`;
      const response = await fetch(url);
      console.log(response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const dataList = await response.json();
      const grouped = dataList.reduce((acc, item) => {
        const video_id = item.video_id || 'unknown';
        if (!acc[video_id]) {
          acc[video_id] = {
            items: [],
            description: item.description,
            video_length: item.video_length,
            vehicle: item.vehicle
          };
        }
        
        acc[video_id].items.push({
          ...item,
          lat: parseFloat(item.gps_lat_deg),
          lng: parseFloat(item.gps_long_deg),
          sampleTime: item.sample_time_seg,
          cvatRating: parseFloat(item.cvat_rating),
          iriRating: parseFloat(item.iri_rating || 0),
          frame: item.frame.replace('/home/eddie/videos/', '')
        });
        return acc;
      }, {});

      Object.keys(grouped).forEach(videoID => {
        grouped[videoID].items.sort((a, b) => a.sampleTime - b.sampleTime);
      });

      setGroupedData(grouped);
      setLastFetchedUserID(userID);
    } catch (error) {
      console.error('Error fetching data:', error);
      setGroupedData([]);
      setLastFetchedUserID(null);
    }
  }, [lastFetchedUserID]);

  return { groupedData, fetchData };
};

export default useFetchData;

import React, { useState, useEffect } from 'react';
import './DistressPopup.css';

const DistressPopup = ({ item, isAdmin, onConfirm, onDelete, currentUser}) => {
  const [isEditingType, setIsEditingType] = useState(false);
  const [type, setType] = useState(item.type);
  const [comments, setComments] = useState(item.comments);

  console.log('uid',currentUser?.uid)
  // Initialize type and comments when item changes
  useEffect(() => {
    if (!type) {
      setType(item.type);
    }
    setComments(item.comments);
  }, [item, type]);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  // const handleConfirm = async () => {
  //   if (!isAdmin) return; // Only admin users can confirm changes
  //   try {
  //     await onConfirm(item.id, type, comments);
  //     setIsEditingType(false);
  //   } catch (error) {
  //     console.error("Failed to update distress report:", error);
  //   }
  // };

  // const handleDelete = async () => {
  //   if (!isAdmin) return; // Only admin users can delete
  //   try {
  //     await onDelete(item.id);
  //   } catch (error) {
  //     console.error("Failed to delete distress report:", error);
  //   }
  // };
  const handleConfirm = async () => {
    if (!isAdmin) return;
    try {

      const userUUID = currentUser?.uid; // Ensure you have currentUser available
      if (!userUUID) {
        console.error("User is not logged in.");
        return;
      }

      await onConfirm(item.id, type, comments, userUUID);
      setIsEditingType(false);
    } catch (error) {
      console.error("Failed to update distress report:", error);
    }
  };

  const handleDelete = async () => {
    if (!isAdmin) return; // Only admin users can delete
    try {
      await onDelete(item.id);
    } catch (error) {
      console.error("Failed to delete distress report:", error);
    }
  };



  return (
    <div style={{ maxWidth: '200px' }}>
      <img src={item.image_path} alt="Distress" style={{ width: '100%', maxHeight: '150px' }} />
      <p><strong>Type:</strong> {isAdmin && isEditingType ? (
        <select value={type} onChange={handleTypeChange} disabled={!isAdmin}>
          <option value="POTHOLE">POTHOLE</option>
          <option value="EDGE_CRACK">EDGE_CRACK</option>
          <option value="LINEAR_CRACK">LINEAR_CRACK</option>
          <option value="DEBRIS">DEBRIS</option>
          <option value="INVADING_VEGETATION">INVADING_VEGETATION</option>
          <option value="FLOODING">FLOODING</option>
          <option value="RUTS">RUTS</option>
          <option value="ROAD_HEAVING">ROAD_HEAVING</option>
          <option value="OTHER">OTHER</option>
          <option value="Solved">SOLVED</option>
        </select>
      ) : (
        type
      )}</p>
      <textarea
        value={comments}
        onChange={handleCommentsChange}
        readOnly={!isAdmin} // Non-admin users cannot edit comments
        className="comments-box"
        style={{
          width: '100%',
          height: '80px',
          padding: '5px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          resize: 'none',
          backgroundColor: isAdmin ? '#fff' : '#f8f8f8', // Light grey for non-admin
        }}
        disabled={!isAdmin} // Disable textarea for non-admin users
      />

      {isAdmin && (
        <div className="admin-controls" style={{ marginTop: '10px' }}>
          {!isEditingType ? (
            <button
              onClick={() => setIsEditingType(true)}
              style={{ marginRight: '5px', padding: '5px 10px' }}
            >
              Change Type
            </button>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <button onClick={handleConfirm} style={{ padding: '5px 10px' }}>
                Confirm
              </button>
            </div>
          )}

          <button
            onClick={handleDelete}
            style={{
              marginTop: '10px',
              padding: '5px 10px',
              backgroundColor: '#dc3545',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default DistressPopup;

import React, { useState, useRef, useEffect } from 'react';
import userLogo from '../../uploads/userlogo.png';
import { auth, signInWithEmailAndPassword, signOut } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import './UserLoginPopup.css';

const UserDropdown = ({ fetchData, mapRef, setCurrentUser, currentUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthChecked, setIsAuthChecked] = useState(false); // Added to ensure auth is only checked once
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await signOut(auth);
      setCurrentUser(null); // Clear currentUser in App.js
      fetchData(null); // Clear data on logout
      if (mapRef.current && mapRef.current.clearRoute) {
        mapRef.current.clearRoute();
      }
    } catch (err) {
      console.error('Error logging out:', err);
      setError('Failed to log out. Please try again.');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setCurrentUser(userCredential.user); // Update currentUser in App.js
      fetchData(userCredential.user.uid); // Fetch data on login
      setEmail('');
      setPassword('');
      setError('');
    } catch (err) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (!isAuthChecked) { // Ensure we only check auth once on mount
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user || null);
        if (user) {
          fetchData(user.uid); // Fetch data once when user logs in
        } else {
          fetchData(null); // Clear data on logout
        }
        setIsAuthChecked(true); // Auth check complete
      });

      return () => unsubscribe();
    }
  }, [isAuthChecked, setCurrentUser, fetchData]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="user-dropdown" ref={dropdownRef}>
      <div className="user-placeholder" onClick={toggleDropdown}>
        <img src={currentUser?.photoURL || userLogo} alt="User Logo" className="user-logo" />
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {currentUser ? (
            <>
              <div className="dropdown-item">
                <span className="dropdown-username">{currentUser.email}</span>
              </div>
              <hr className="dropdown-separator" />
              <div className="dropdown-item">
                <button className="logout-button" onClick={handleLogout}>Log Out</button>
              </div>
            </>
          ) : (
            <form onSubmit={handleLogin} className="login-form">
              {error && <p className="login-error">{error}</p>}
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-button">Log In</button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
